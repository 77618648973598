
import ARB_ADDRESS  from '../mainnet/arbAddress'

export function getAddress () {
  return ARB_ADDRESS
}

export function getCurAddress () {
  return getAddress()
}
